import {InteractionRequiredAuthError, InteractionStatus, PublicClientApplication} from '@azure/msal-browser';
import {IMsalContext, useMsal} from '@azure/msal-react';
import React, {useEffect, useState } from 'react';
import { loginRequest } from '../authConfig';
import { msalInstance } from '../index';
import acquireAccessToken from './msalUtil';

export interface WeatherForecastModel
{
    date : string;
    temperatureC : number;
    temperatureF : number;
    summary : string;
}

const callWeatherForecastApiAsync = async () => {
    const endpoint = 'https://api-1.guyco.ca/WeatherForecast';  // TODO:
    const headers = new Headers();
    
    const token = await acquireAccessToken();
    
    const bearer = `Bearer ${token}`;
    
    headers.append('Authorization', bearer);

    const options = {
        method: 'GET',
        headers: headers,
    };

    return fetch(endpoint, options)
        .then(response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return response.json() as Promise<WeatherForecastModel[]>
        });
}

export default callWeatherForecastApiAsync;