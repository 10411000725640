import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import {IMsalContext, useMsal} from '@azure/msal-react';
import React, {useEffect, useState } from 'react';
import { loginRequest } from '../authConfig';

import callWeatherForecastApiAsync, {WeatherForecastModel} from '../services/weatherForecastService';

// function callWeatherForecastApi(token : string) {
//     const endpoint = 'https://api-1.guyco.ca/WeatherForecast';  // TODO:
//     const headers = new Headers();
//     const bearer = `Bearer ${token}`;
//
//     headers.append('Authorization', bearer);
//
//     const options = {
//         method: 'GET',
//         headers: headers,
//     };
//
//     return fetch(endpoint, options)
//         .then(response => {
//             if (!response.ok) {
//                 throw new Error(response.statusText)
//             }
//             return response.json() as Promise<WeatherForecast[]>
//         });
// }


const WeatherForecast = () => 
{
    const [data, setData] = useState<WeatherForecastModel[]>( [] );
    
    const getData = async () => {
        var result = await callWeatherForecastApiAsync();
        
        setData(await result);
    }
    
    return (
        <>
        <button onClick={() => getData()}>Get Data</button>  
        <table className="table table-striped" aria-labelledby="tabelLabel">
            <thead>
            <tr>
                <th>Date cc</th>
                <th>Temp. (C)</th>
                <th>Temp. (F)</th>
                <th>Summary</th>
            </tr>
            </thead>
            <tbody>
            {data.map((forecast) => (
                <tr key={forecast.date}>
                    <td>{forecast.date}</td>
                    <td>{forecast.temperatureC}</td>
                    <td>{forecast.temperatureF}</td>
                    <td>{forecast.summary}</td>
                </tr>
            ))}
            </tbody>
        </table>
        </>

    );
}

export default WeatherForecast;