import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import React from "react";
import { PageLayout } from "./components/PageLayout";
import WeatherForecast from "./components/WeatherForecast";
import Claims from "./components/Claims";

function App() {
  return (
      <PageLayout>
          <AuthenticatedTemplate>
              <WeatherForecast/>
              
              <Claims/>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
              Not authenticated
          </UnauthenticatedTemplate>
      </PageLayout>
  );
}

export default App;