import {InteractionRequiredAuthError, InteractionStatus, PublicClientApplication} from '@azure/msal-browser';
import {IMsalContext, useMsal} from '@azure/msal-react';
import React, {useEffect, useState } from 'react';
import { loginRequest } from '../authConfig';
import { msalInstance } from '../index';
import acquireAccessToken from './msalUtil';

export interface ClaimModel
{
    issuer: string;
    properties: {[id : string] : string };
    subject: string;
    type: string;
    value: string;
    originalIssuer: string;
    valueType: string;
}

const callClaimsApiAsync = async () => {
    const endpoint = 'https://api-1.guyco.ca/Claims';  // TODO:
    const headers = new Headers();

    const token = await acquireAccessToken();

    const bearer = `Bearer ${token}`;

    headers.append('Authorization', bearer);

    const options = {
        method: 'GET',
        headers: headers,
    };

    return fetch(endpoint, options)
        .then(response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return response.json() as Promise<ClaimModel[]>
        });
}

export default callClaimsApiAsync;