import React from 'react';
import {useMsal} from '@azure/msal-react';
import {loginRequest} from '../authConfig';
import Button from "react-bootstrap/Button";
import { IPublicClientApplication } from '@azure/msal-browser';

function handleLogin(instance : IPublicClientApplication) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

export const SignInButton = () => {
    const {instance} = useMsal();
    
    return (
        <Button variant="secondary" className="ml-auto" onClick={() => handleLogin(instance)}>Sign in</Button>
    )
}