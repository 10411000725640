export const msalConfig = {
    auth: {
        clientId: "66f83ac4-cd5f-42f3-b92f-eabe0e9e6dd3",
        //authority: "https://guycob2c.b2clogin.com/guycob2c.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN",
        authority: "https://guycob2c.b2clogin.com/guycob2c.onmicrosoft.com/B2C_1_SignUpSignIn_UserFlow",
        knownAuthorities: ["guycob2c.b2clogin.com"],
        redirectUri: process.env.PUBLIC_URL,
        validateAuthority: false
        
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["https://guycob2c.onmicrosoft.com/api-1/access_as_user"],
    extraQueryParameters: {"myCustomParameter": "some-custom-value"}
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};