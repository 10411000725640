import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import {IMsalContext, useMsal} from '@azure/msal-react';
import React, {useEffect, useState } from 'react';
import { loginRequest } from '../authConfig';

import callClaimsApiAsync, {ClaimModel} from '../services/claimsService';

// function callWeatherForecastApi(token : string) {
//     const endpoint = 'https://api-1.guyco.ca/WeatherForecast';  // TODO:
//     const headers = new Headers();
//     const bearer = `Bearer ${token}`;
//
//     headers.append('Authorization', bearer);
//
//     const options = {
//         method: 'GET',
//         headers: headers,
//     };
//
//     return fetch(endpoint, options)
//         .then(response => {
//             if (!response.ok) {
//                 throw new Error(response.statusText)
//             }
//             return response.json() as Promise<WeatherForecast[]>
//         });
// }


const Claims = () =>
{
    const [data, setData] = useState<ClaimModel[]>( [] );

    const getData = async () => {
        var result = await callClaimsApiAsync();

        setData(await result);
    }

    return (
        <>
            <button onClick={() => getData()}>Get Data</button>
            <table className="table table-striped" aria-labelledby="tabelLabel">
                <thead>
                <tr>
                    <th>Issuer</th>
                    <th>Subject</th>
                    <th>Type</th>
                    <th>Value</th>
                    <th>OriginalIssuer</th>
                    <th>ValueType</th>
                </tr>
                </thead>
                <tbody>
                {data.map((claim) => (
                    <tr key={claim.value}>
                        <td>{claim.issuer}</td>
                        <td>{claim.subject}</td>
                        <td>{claim.type}</td>
                        <td>{claim.value}</td>
                        <td>{claim.originalIssuer}</td>
                        <td>{claim.valueType}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>

    );
}

export default Claims;